import { Component, Host, h, Prop, getAssetPath } from '@stencil/core';

@Component({
  tag: 'x-sprite',
  assetsDirs: ['assets'],
})
export class XSprite {
  @Prop() src: string;

  connectedCallback() {
    const internal = fetch(getAssetPath('./assets/sprite.svg')).then(response => response.text());
    const external = fetch(this.src).then(response => response.text());

    Promise.allSettled([internal, external]).then(res => {
      const div = document.createElement('div');

      res.forEach((data: any) => {
        div.innerHTML += data.value;
      });

      div.setAttribute('hidden', '');
      document.body.appendChild(div);
    });
  }

  render() {
    return <Host></Host>;
  }
}
